<template>
  <div class="material-video-box">
    <el-dialog
      class="customize-el-dialog"
      title="上传视频"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
      width="850"
    >
      <div style="border-bottom: 1px solid #e9eaeb">
        <div class="flex-between mgn-b20">
          <div class="flex-start">
            <div class="material-video-search-box">
              <el-input
                placeholder="搜索内容标题/ID/创建者"
                suffix-icon="el-icon-search"
                @change="onSearchChange"
                v-model="searchData.searchKey"
              >
              </el-input>
            </div>
            <div class="pdg-lr10 select-item">
              <el-select
                v-model="searchData.isQueryAll"
                @change="onSelectChange"
                placeholder="素材来源"
                style="width: 110px"
              >
                <el-option
                  v-for="item in orgSource"
                  :key="item.isQueryAll"
                  :label="item.title"
                  :value="item.isQueryAll"
                >
                </el-option>
              </el-select>
            </div>
            <div class="pdg-lr10 select-item">
              <el-select
                v-model="searchData.tagId"
                style="width: 110px"
                placeholder="所有产品"
                class="reset-item"
                clearable
                @change="onSelectChange"
              >
                <el-option
                  v-for="product in productList"
                  :key="product.tagId"
                  :label="product.tagName"
                  :value="product.tagId"
                />
              </el-select>
            </div>
            <div class="pdg-lr10 select-item" v-if="searchData.isQueryAll === false">
              <el-select
                v-model="searchData.categoryId"
                @change="onSelectChange"
                style="width: 110px"
                placeholder="所有分类"
              >
                <el-option
                  v-for="item in videoClassifyList"
                  :key="item.categoryId"
                  :label="item.title"
                  :value="item.categoryId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <span class="text-primary-link" @click="onCreate">本地上传</span>
        </div>
        <el-table :data="tableDataList" border style="width: 100%">
          <el-table-column width="55" align="center">
            <template slot-scope="scope">
              <el-checkbox
                :value="isSelect(scope.row.videoId)"
                @change="onCheckedChange(scope.row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="videoId"
            label="ID"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="title"
            label="视频名称"
            align="center"
            min-width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scoped">
              <el-button type="text" @click="onOpenDialog(scoped.row)">{{
                scoped.row.title
              }}</el-button>
            </template></el-table-column
          >
          <el-table-column
            prop="duration"
            label="视频时长(分钟)"
            align="center"
          >
            <template slot-scope="scope">
              {{ parseFloat(((scope.row.duration || 0) / 60).toFixed(1)) }}
            </template>
          </el-table-column>
          <!-- 产品标签 -->
          <el-table-column prop="productTag" label="产品标签" align="center">
            <template slot-scope="scope">
              <span v-for="(item, index) of scope.row.tagInfos" :key="index">
                {{ item.tagName }}
              </span>
            </template>
          </el-table-column>
          <!-- 上传时间 -->
          <el-table-column
            prop="dateTime"
            label="上传时间"
            align="center"
          ></el-table-column>
        </el-table>
        <el-pagination
          class="sorter-box"
          @current-change="onCurrentChange"
          :current-page="searchData.pageNo"
          :page-size="searchData.pageSize"
          :total="total"
          background
          hide-on-single-page
          layout="total, prev, pager, next"
          small
        ></el-pagination>
      </div>
      <div slot="footer" class="flex-center">
        <el-button @click="onClose">取消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <video-viewer v-model="showVideoVewer" :video-url="currentSrc" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VideoViewer from "../views/material/components/VideoViewer.vue";
export default {
  name: "MaterialVideo",
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: false,
    },
    showVideoDuration: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 100,
    },
    initCheckedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    VideoViewer,
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.initPage();
      }
    },
  },
  data() {
    return {
      // -------------------- 搜索、页数 -----------------
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 5,
        categoryId: "",
        searchKey: "",
        isQueryAll: true,
        tagId: "",
      },
      // ---------------------- select -----------------
      videoClassifyList: [],
      // -------------------- submit ----------------
      btnLoading: false,
      // ----------------------- vedio table -----------------
      tableDataList: [],
      selectedList: [],
      orgSource: [
        { isQueryAll: true, title: "所有素材" },
        { isQueryAll: false, title: "我的素材" },
      ],
      productList: [],
      showVideoVewer: false,
      currentSrc: {},
    };
  },

  methods: {
    ...mapActions(["onGetTrainVideoList", "onGetCategoryList"]),
    onClose() {
      this.$emit("onClose", false);
    },
    onCreate() {
      this.$emit("onUpload", false);
      this.onClose();
    },
    onSubmit() {
      this.$emit("onSubmit", this.selectedList);
      this.onClose();
    },
    onSearchChange() {
      this.$set(this.searchData, "pageNo", 1);
      this.onVideoList();
    },
    onSelectChange() {
      this.$set(this.searchData, "pageNo", 1);
      this.onVideoList();
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 5,
        categoryId: "",
        searchKey: "",
        isQueryAll: true,
        tagId: "",
      };
      this.selectedList = this.initCheckedList;
      this.tableDataList = [];
      this.onVideoList();
      this.onGetVideoCategoryList();
      this.getTagGroup().then((res) => {
        const row = res.find((item) => item.groupName.includes("产品"));
        this.getProdectList(row.id);
      });
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup);
      if (data.length) {
        this.tagGroup = data;
      }
      return data;
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId,
      };
      const { data } = await this.$axios.get(this.$API.tagList, { params });
      if (data.length) {
        this.productList = data;
      }
    },
    onGetVideoCategoryList() {
      this.onGetCategoryList({ categoryType: "video" }).then((res) => {
        this.videoClassifyList = res.data || [];
      });
    },
    onOpenDialog(row) {
      if (row.status === 0) return;
      const { playUrl = [] } = row;
      if (!playUrl) {
        this.$message.error("当前视频没有播放地址");
        return;
      }
      this.showVideoVewer = true;
      this.currentSrc = {
        mp4: {
          hd: this.findUrl(".mp4", "hd", playUrl),
          sd: this.findUrl(".mp4", "sd", playUrl),
          ld: this.findUrl(".mp4", "ld", playUrl),
          fd: this.findUrl(".mp4", "fd", playUrl),
        },
        hls: {
          hd: this.findUrl(".m3u8", "hd", playUrl),
          sd: this.findUrl(".m3u8", "sd", playUrl),
          ld: this.findUrl(".m3u8", "ld", playUrl),
          fd: this.findUrl(".m3u8", "fd", playUrl),
        },
      };
    },
    findUrl(type, clarity, arr) {
      let url = "";
      arr = arr || [];
      arr.some((item) => {
        if (item.indexOf(type) !== -1 && item.indexOf(clarity) !== -1) {
          url = item;
          return true;
        }
        return false;
      });
      return url;
    },
    onVideoList() {
      this.onGetTrainVideoList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0;
          this.tableDataList = data || [];
        }
      );
    },
    isSelect(currentId) {
      return (
        [...this.selectedList].map((v) => v.videoId).indexOf(currentId) > -1
      );
    },
    onCheckedChange(item) {
      if (this.isSelect(item.videoId)) {
        this.selectedList = [...this.selectedList].filter(
          (v) => v.videoId !== item.videoId
        );
      } else {
        if (this.limit === 1) {
          this.selectedList = [];
        }
        this.selectedList = [...this.selectedList, item];
      }
    },
    onCurrentChange(options) {
      this.$set(this.searchData, "pageNo", options);
      this.onVideoList();
    },
  },
};
</script>

<style lang="scss" scoped>
.material-video-search-box .el-input input {
  width: 250px;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .material-video-box {
    ::v-deep .el-dialog__wrapper .el-dialog {
      height: 80% !important;

      .el-dialog__body {
        padding-top: 20px !important;
        overflow: auto;
        height: 80% !important;
      }
    }
  }
}

.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin-right: 20px;
}

.bgf6f7fb {
  background: #f6f7fb;
}

.sorter-box {
  padding: 10px;
  text-align: right;
  background: #ffffff;
}
</style>
