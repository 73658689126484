<template>
  <div class="exam-item" v-if="!item.richTextContent">
    <div class="title">
      {{ index }}. {{ item.title | textFilter }}（{{
        item.examTypeEnum | examTypeFilter
      }}
      {{
        item.leastAnswerCount ? "至少选" + item.leastAnswerCount + "项" : ""
      }}）
    </div>
    <div
      v-if="item.examRichTextContent"
      v-safe-html="item.examRichTextContent"
      style="width: 30%"
    ></div>
    <div v-if="item.examTypeEnum === 'scale'">
      <div class="flex-between pdg10">
        <div class="flex-box align-center" />
        <div
          v-for="(v, i) in item.answers"
          :key="i"
          class="flex-box flex-center"
        >
          <span>{{ v.answer || v.title }}</span>
        </div>
      </div>
      <el-row
        v-for="(v, i) in item.scaleQuestionList"
        :key="i"
        type="flex"
        align="middle"
        class="exam-answer-wrapper flex-between border-bottom"
      >
        <div class="flex-box">{{ v }}</div>
        <div
          v-for="(t, s) in item.answers"
          :key="s"
          class="flex-center flex-box"
        >
          <div class="answer-item" />
        </div>
      </el-row>
    </div>
    <div
      v-else-if="item.examTypeEnum === 'blank_fill'"
      class="answer-item mg-t10"
    >
    <div v-if="item.answers && item.answers.length">
      推荐/正确选项：
      <span v-for="(v, i) in item.answers" :key="i"
        >填空{{ i + 1 }}：{{ v.answer || v.title }}
      </span>
    </div>
    </div>
    <div
      v-else-if="item.examTypeEnum === 'text_fill' && item.type === 0"
      class="text-fill"
    ></div>
    <div v-else class="answer-item" v-for="(v, i) in item.answers" :key="i">
      <el-radio
        v-if="item.examTypeEnum === 'single'"
        :value="v.isSelect || v.secondSelect"
        :label="true"
      >
        <span class="color-desc"
          >{{ answerIndex[i] }}. {{ v.answer || v.title }}</span
        >
        <span
          class="color-assist"
          v-if="v.isCorrect"
          >（推荐/正确选项）</span
        >
      </el-radio>
      <el-checkbox v-else :value="v.isSelect || v.secondSelect" :label="true">
        <span class="color-desc"
          >{{ answerIndex[i] }}. {{ v.answer || v.title }}</span
        >
        <span
          class="color-assist"
          v-if="v.isCorrect"
          >（推荐/正确选项）</span
        >
      </el-checkbox>
    </div>
    <div v-if="showSubmitAnswer && item.submitAnswer" class="answer-item mg-t10">
      医生答案：{{ doctorAnswer }}
    </div>
    <div v-if="item.examExpand" class="answer-item mg-t10">
      推荐/正确选项：{{
        item.examTypeEnum === "sort"
          ? filterCorrect(item.answers)
          : item.examExpand
      }}
    </div>
    <div v-if="item.analysis" class="answer-item mg-t10">
      题目解析：{{ item.analysis }}
    </div>
    <div v-if="item.tagInfos && item.tagInfos.length" class="answer-item mg-t10">
      标签/关键信息：<ProductLabelKnowledge
        :selected-tag-list="item.tagInfos"
        :disabled-ids="disabledIds"
      />
    </div>
  </div>
  <div v-else>
    <span v-safe-html="item.richTextContent"></span>
  </div>
</template>
<script>
import ProductLabelKnowledge from "@/bizComponents/ProductLabelKnowledge";
export default {
  name: "ExamItem",
  components: {
    ProductLabelKnowledge,
  },
  data() {
    return {
      answerIndex: Array.from(new Array(26), (ele, index) => {
        return String.fromCharCode(65 + index)
      }),
    }
  },
  props: {
    showSubmitAnswer: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    indexFilter(i) {
      let arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T']
      return arr[i]
    },
    textFilter(text) {
      return text.replace(/<filter><\/filter>/g, " ______ ");
    },
    examTypeFilter(type) {
      switch (type) {
        case "single":
          return "单选题";
        case "multiple":
          return "多选题";
        case "text_fill":
          return "问答题";
        case "blank_fill":
          return "填空题";
        case "sort":
          return "排序题";
        case "scale":
          return "量表题";
      }
    },
  },
  computed: {
    doctorAnswer() {
      if (this.item.answers) {
        const submitAnswer = this.item.submitAnswer.split(",");
        const answers = this.item.answers;
        let answer = [];
        answers.map((v) => {
          submitAnswer.map((i, k) => {
            if (v.examAnswerId == i) {
              if (this.item.examTypeEnum === "scale") {
                answer.push(v.answer);
              } else {
                answer.push(this.answerIndexFilter(k + 1));
              }
            }
          });
        });
        if (this.item.examTypeEnum === "sort") {
          return answer.join(" => ");
        } else {
          return answer.join("，");
        }
      } else {
        return this.item.submitAnswer;
      }
    },
    filterCorrect() {
      return (list) => {
        const newList = JSON.parse(JSON.stringify(list));
        const strArr = [];
        if (this.item.examExpand) {
          const examExpandList = this.item.examExpand.split(",");
          examExpandList.forEach((it) => {
            newList.forEach((item, ind) => {
              item.titleStr = this.answerIndex[ind];
              if (item.examAnswerId == it) {
                strArr.push(item.titleStr)
              }
              return item;
            });
          });
        }
        return strArr.join("、");
      };
    },
    disabledIds() {
      const arr = [];
      this.item.tagInfos?.map((v) => {
        arr.push(v.tagId);
        v.knowledgePointInfos?.map((k) => {
          arr.push(k.knowledgePointId);
        });
      });
      return arr;
    },
  },
  methods: {
    answerIndexFilter(i) {
      return this.answerIndex[i - 1]
    },
  },
};
</script>
<style lang="scss" scoped>
.exam-item {
  padding: 12px;
  .title {
    color: #272e40;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .answer-item {
    padding: 8px 0;
    color: #969696;
  }
  .exam-answer-wrapper {
    padding-bottom: 6px;
    padding-top: 6px;
    position: relative;
    .answer-opt-wrapper {
      position: absolute;
      left: calc(100% + 10px);
    }
    .opt-label {
      cursor: pointer;
      margin-right: 10px;
      font-size: 20px;
      color: $color-primary;
    }
    .answer-item {
      position: relative;
      color: #7784a1;
      width: 16px;
      height: 16px;
      border: 1px solid #e1e1e1;
      flex-shrink: 0;
      border-radius: 50%;
    }
  }
  .border-bottom {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  .fill-answer {
    display: inline-block;
  }
  .text-fill {
    border: 1px solid #e1e1e1;
    width: 400px;
    height: 24px;
    margin-bottom: 10px;
  }
}
.mg-t10 {
  margin-top: 10px;
}
</style>
