<template>
  <div class="add-question-pop-box">
    <el-dialog
      title="添加问题"
      :visible.sync="visible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="content">
        <div class="item-box left-box content-box">
          <div style="margin-bottom: 20px;">
            <el-row
              type="flex"
              justify="space-between"
              class="pdg-lr10 pdg-tb10"
            >
              <div class="col272e40 font-bold">
                <span>题库</span>
                <el-tooltip effect="dark" content="刷新题库" placement="bottom">
                  <span class="mgn-l10" @click="onGetQuestionList">
                    <svg-icon name="el-icon-refresh" />
                  </span>
                </el-tooltip>
              </div>
              <span class="text-primary-link" @click="onCreate">新建题目</span>
            </el-row>
            <el-row type="flex" justify="space-between">
              <div class="material-question-search-box">
                <el-input
                  v-model="searchData.searchKey"
                  placeholder="搜索内容标题/ID/创建者"
                  suffix-icon="el-icon-search"
                  @change="onSearchChange"
                />
              </div>
              <div class="pdg-lr10 select-item">
                <el-select
                  v-model="searchData.isQueryAll"
                  placeholder="素材来源"
                  style="width: 110px;"
                  @change="onSelectChange"
                >
                  <el-option
                    v-for="item in orgSource"
                    :key="item.isQueryAll"
                    :label="item.title"
                    :value="item.isQueryAll"
                  />
                </el-select>
              </div>
              <div class="pdg-lr10 select-item">
                <el-select
                  v-model="searchData.productTagId"
                  style="width: 110px;"
                  placeholder="所有产品"
                  class="reset-item"
                  clearable
                  @change="onSelectChange"
                >
                  <el-option
                    v-for="product in productList"
                    :key="product.tagId"
                    :label="product.tagName"
                    :value="product.tagId"
                  />
                </el-select>
              </div>
              <div v-if="searchData.isQueryAll === false" class="pdg-lr10">
                <el-select
                  v-model="searchData.categoryId"
                  placeholder="所有分类"
                  style="width: 110px;"
                  clearable
                  @change="onSelectChange"
                >
                  <el-option
                    v-for="item in examClassifyList"
                    :key="item.categoryId"
                    :label="item.title"
                    :value="item.categoryId"
                  />
                </el-select>
              </div>
            </el-row>
          </div>

          <div class="content-box" style="flex: 1;">
            <el-table
              v-loading="btnLoading"
              :data="sourceList"
              style="width: 100%;"
              border
            >
              <el-table-column
                prop="examId"
                label="ID"
                width="70"
                show-overflow-tooltip
              />
              <el-table-column
                prop="title"
                label="题目标题"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tooltip
                    :disabled="scope.row.title.length < 10"
                    :open-delay="1000"
                    class="item"
                    effect="dark"
                    :content="textFilter(scope.row.title)"
                    placement="top-start"
                  >
                    <div class="overflow-text grow-shrink0" style="width: 310px;">
                      <el-button
                        type="text"
                        @click="onView(scope.row)"
                      >
                        {{ textFilter(scope.row.title) }}
                      </el-button>
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="examTypeEnum"
                label="题目类型"
                align="center"
                width="70"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ examTypeEnum(scope.row.examTypeEnum) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="answerType"
                label="推荐选项/正确选项"
                width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-for="item in scope.row.answers" :key="item.examAnswerId">
                    {{ item.answer || '/' }}、
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="70"
              >
                <template slot-scope="scope">
                  <el-row
                    type="flex"
                    justify="center"
                  >
                    <span
                      v-if="isSelect(scope.row.examId)"
                      style="color: #c8c9ce;"
                    >已选择</span>
                    <div
                      v-else
                      class="cursor"
                      style="text-align: center;"
                      @click="onAdd(scope.row)"
                    >
                      <i class="el-icon-circle-plus-outline opt-label" />
                    </div>
                  </el-row>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="sorter-box"
              :current-page="searchData.pageNo"
              :page-size="searchData.pageSize"
              :total="total"
              :small="true"
              :pager-count="pagerCount"
              background
              hide-on-single-page
              layout="total, prev, pager, next, jumper"
              @current-change="onCurrentChange"
            />
          </div>
        </div>
        <div class="item-box right-box content-box">
          <div style="margin-bottom: 28px;">
            <div class="col272e40 font-bold pdg-lr10 pdg-tb10">
              当前选择问题（已选择{{ targetList.length || 0 }}题）
            </div>
            <div class="col7784a1 pdg-lr10">请从左侧问题列表选择添加</div>
          </div>
          <el-table
            v-loading="btnLoading"
            :data="targetList"
            style="width: 100%;"
            height="400px"
            border
          >
            <el-table-column
              prop="examId"
              label="ID"
              width="70"
            />
            <el-table-column
              prop="examTypeEnum"
              label="题目类型"
              align="center"
              width="70"
            >
              <template slot-scope="scope">
                <span>{{ examTypeEnum(scope.row.examTypeEnum || scope.row.answerType) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
            >
              <template slot-scope="scope">
                <div
                  class="cursor"
                  style="text-align: center;"
                  @click="onDel(scope.row.examId)"
                >
                  <i class="el-icon-remove-outline opt-label" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flex-center mgn-t20">
        <el-button class="w100" plain @click="onClose">取消</el-button>
        <el-button
          class="w100"
          :loading="btnLoading"
          type="primary"
          @click="onSubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
    <ExamPre
      :visible="isShowPre"
      :item-obj="itemObj"
      :is-show-correct="isSetCorrectAnswer"
      @onClose="isShowPre = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AddExamModal',
  components: {},
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: false
    },
    selectList: {
      type: Array,
      default() {
        return []
      }
    },
    examType: {
      type: Number,
      default: 0
    },
    suitableType: {
      type: String,
      default: '1'
    },
    limit: {
      type: Number
    },
    showAllExam: {
      type: String,
      default: ''
    },
    tagId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        type: 0,
        searchKey: '',
        categoryId: '',
        isQueryAll: true,
        productTagId: ''
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: [],
      isShowPre: false,
      itemObj: {},
      isSetCorrectAnswer: false,
      orgSource: [
        { isQueryAll: true, title: '所有素材' },
        { isQueryAll: false, title: '我的素材' }
      ],
      productList: []
    }
  },
  computed: {
    isSelect() {
      return currentId => {
        const targetSelect = [...this.targetList].map(v => v.examId)
        return targetSelect.indexOf(currentId) > -1
      }
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.initPage()
      }
    }
  },
  methods: {
    ...mapActions(['onGetTrainExamList', 'onGetCategoryList']),
    examTypeEnum(type) {
      let answerType = ''
      switch (type) {
        case 'single':
          answerType = '单选题'
          break
        case 'multiple':
          answerType = '多选题'
          break
        case 'text_fill':
          answerType = '问答题'
          break
        case 'blank_fill':
          answerType = '填空题'
          break
        case 'sort':
          answerType = '排序题'
          break
        case 'scale':
          answerType = '量表题'
          break
      }
      return answerType
    },
    textFilter(value) {
      return value.replace(/<filter><\/filter>/g, ' ______ ')
    },
    onView(item) {
      this.isShowPre = true
      this.isSetCorrectAnswer = item.isSetCorrectAnswer
      this.itemObj = item
    },
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve(
        `/material/edit/exam?from=addExamModal&type=${this.suitableType}`
      )
      window.open(routeUrl.href, '_blank')
    },
    onSubmit() {
      if (this.limit && this.targetList.length > this.limit) {
        this.$message.error(`您最多可以选择${this.limit}道题目`)
        return
      }
      this.$emit('onSubmit', this.targetList)
      this.onClose()
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 10,
        isSetCorrectAnswer: !this.examType ? !this.examType : '',
        searchKey: '',
        answerType: [],
        categoryId: '',
        suitableType: this.suitableType,
        isQueryAll: true,
        productTagId: this.tagId ? this.tagId : ''
      }
      this.sourceList = []
      this.targetList = this.selectList
      this.onGetQuestionList()
      this.onGetExamCategoryList()
      this.getTagGroup().then(res => {
        const row = res.find(item => item.groupName.includes('产品'))
        this.getProdectList(row.id)
      })
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    onGetQuestionList() {
      this.onGetTrainExamList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0
          this.sourceList = data || []
        }
      )
    },
    onGetExamCategoryList() {
      this.onGetCategoryList({ categoryType: 'exam' }).then(res => {
        this.examClassifyList = res.data || []
      })
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetQuestionList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetQuestionList()
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetQuestionList()
    },
    onAdd(item) {
      if (this.limit) {
        if (this.limit > this.targetList.length) {
          this.targetList = [...this.targetList, item]
        } else {
          this.$message.error(`您最多只能添加${this.limit}题`)
        }
      } else {
        this.targetList = [...this.targetList, item]
      }
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(
        v => v.examId !== currentId
      )
    }
  }
}
</script>

<style>
.add-question-pop-box .el-dialog {
  width: 1000px !important;
}
.add-question-pop-box .el-dialog__body {
  padding: 0;
}
</style>
<style lang='scss' scoped>
.material-question-search-box ::v-deep {
  flex: 1;
  padding-right: 15px;
  .el-input input {
    border-radius: 20px;
  }
}
.opt-label {
  color: #3d61e3;
  font-size: 20px;
}
.content {
  display: flex;
  justify-content: space-between;
}
.item-box {
  height: 530px;
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}
.left-box {
  width: 75%;
  margin-right: 10px;
}
.content-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.right-box {
  width: 25%;
}
.check-box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
//.check-box::-webkit-scrollbar {
//  width: 0 !important;
//}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
</style>
